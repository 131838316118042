body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-grid .container {
  width: 95%;
  border: solid thin;
  padding: 15px !important;
}

.custom-grid .inner-container {
  width: 95%;
  border: solid thin #ccc;
  padding: 15px !important;
  margin-bottom: 15px !important;
}

.inner-container .form-group {
  margin-bottom: 1rem;
  padding-top: 1rem;
  border-top: solid thin #a2a2a2;
  margin-top: 2rem;
}

.custom-grid > .container .row {
  margin-bottom: 1rem;
}

.custom-grid > .container {
  margin-bottom: 1rem;
}

.custom-grid > .container .btn  {
  margin-bottom: 0.5rem; 
}

.small-text {
  font-size: 0.7rem;
  color: cadetblue;
}

.smallMarginBottom  div,.smallMarginBottom  button {
  margin-bottom: 0.1rem !important; 
}

.rbc-timeslot-group {
  min-height: 100px !important;
}


.ticketInfoBox {
  background-color: white;
  color: black;
  margin-top: 3px;
  margin-bottom: 0px !important;
  padding-top: 5px;
  height: 100%;
}


.inner-container .form-group {
  margin-bottom: 1rem;
  padding-top: 0rem !important;
  border-top: none !important;
  margin-top: 0rem !important;
}

.rbc-event-label {
  display: none  ;
  font-size: 1.2rem;
  font-weight: bold;
}

.rbc-event {
  width: 100% !important;
  left: 0px !important;
  padding: 0px !important;
}

.MuiLinearProgress-root {
  height: 40px !important;
}


.label {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 2rem;
  padding-top: 4px;
}

.value {
  font-size: 1.5rem;
  line-height: 2rem;
}


.label-important {
  font-size: 2rem;
  font-weight: bold;
}


.label-waiting {
  color: blue;
}

.label-called {
  color: rgb(95, 95, 43);
}

.label-last-call {
  color: red;
}

.block > span {
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
}

.align-text-dyn {
  vertical-align: center;
}

@media (min-width:768px) {
  .align-text-dyn {
    text-align: right;
    padding: 0.5rem 0;
  }
}

.bookableSlot:hover {
  background-color: #7ec296 !important;
  cursor: grab;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}